import { ThemeVars } from "../_model";

export default {
  id: "Slido Knockoff",
  theme: {
    admin_index: 0,
    user_index: 0,
    schemes: [
      {
        p: {
          bg: "#0D2937",
          text: "#ffffff",
        },
      },
    ] as ThemeVars[],
  },
};
